import * as React from 'react'

import Layout from 'components/global/layout'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import MaxWidth from 'components/functional/maxWidth'
import PageHeader from 'components/shared/pageHeader'
import { media } from 'utils/Media'

const NotFoundStyles = styled.div`
  width: 100%;
  text-align: center;
  padding: 2rem 0;
  strong {
    font-size: 2rem;
  }
  p {
    font-weight: 700;
    margin: 1.25rem 0;
    @media ${media.sm} {
      font-size: 1.5rem;
    }
  }
`

const NotFoundPage = ({ data }) => {
  const mob = data.mobHeaderImg
  const desk = data.deskHeaderImg
  return (
    <Layout>
      <PageHeader mobileHeader={mob} desktopHeader={desk} />
      <MaxWidth>
        <NotFoundStyles>
          <h2>404</h2>
          <p>The page your were looking for does not exist.</p>
          <Link to="/">
            <button type="button" className="btn btn-main">
              <span>HOME</span>
            </button>
          </Link>
        </NotFoundStyles>
      </MaxWidth>
    </Layout>
  )
}

export default NotFoundPage

export const ErrorQuery = graphql`
  query {
    mobHeaderImg: file(name: { eq: "mt-tour-header" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "mt-tour-header" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
